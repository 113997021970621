import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { FormControl } from "baseui/form-control"
import { Value } from "baseui/select"
import { HeadingXSmall, LabelSmall } from "baseui/typography"
import moment from "moment-timezone"
import { useMutation, useQuery } from "react-fetching-library"
import { useForm } from "react-hook-form"

import { CancelAndSaveButtons } from "../../../components/cancelSaveButtons"
import { Divider, Spacer, ZenCard } from "../../../components/common"
import { ErrorNotification } from "../../../components/errorBox"
import { FieldDisplay } from "../../../components/fieldDisplay"
import { LoadingOverlay } from "../../../components/loadingOverlay"
import { ZenButton } from "../../../components/zenComponents/zenButtons"
import { ZenCheckbox } from "../../../components/zenComponents/zenCheckboxList"
import { ZenConfirmModal } from "../../../components/zenComponents/zenConfirmModal"
import { ZenInput } from "../../../components/zenComponents/zenInput"
import { ZenSelect } from "../../../components/zenComponents/zenSelectBox"
import { ZenDatePicker } from "../../../components/zenComponents/zenTime"
import { fetching } from "../../../fetching"
import { currencyRegex, useDebounce } from "../../../helpers/utils"
import { ZenTheme } from "../../../themeOverrides"
import { NDISPriceType } from "../../../types/enums"
import { NDISLineItem, NDISLineItemManager, NDISPlan, NDISPlanLine, ZenDateFormat } from "../../../types/types"

interface AddNDISLineProps {
	planLine?: NDISPlanLine
	plan: NDISPlan
	planLines?: NDISPlanLine[]
	ndisNumber: string
	onSuccess: (plan: NDISPlanLine) => void
	onCancel: () => void
	onDeleteNotify: () => void
}

interface NDISLinesFormValues {
	lineItem: NDISLineItem[]
	managedBy: NDISLineItemManager[]
	planManager?: NDISLineItemManager[]
	budget: string
	inGapManagement: boolean
	isincludedinutilisation: boolean
	gapManagementFrom?: string
	gapManagementTo?: string
	serviceStartDate: string
	serviceEndDate: string
	includesTransport: boolean
	chargeTransportTo: NDISLineItemManager[]
	transportPlanManager: NDISLineItemManager[]
	transportValue: string
	includesProviderTravel: boolean
	chargeProviderTravelTo: NDISLineItemManager[]
	providerTravelPlanManager: NDISLineItemManager[]
	providerTravelValue: string
}

// AddNDISLine used for creating and editing NDIS plan line items
export const AddNDISLine = (props: AddNDISLineProps) => {
	const { planLine, plan, planLines, ndisNumber, onSuccess, onDeleteNotify, onCancel } = props
	const { control, handleSubmit, getValues, errors, watch, setValue } = useForm()
	const inGapManagement = watch("inGapManagement")

	const [css, theme] = useStyletron()
	const card = css({
		minWidth: "680px",
	})
	const planInfo = css({
		display: "flex",
		backgroundColor: ZenTheme.colors.lightGrey,
		padding: "10px",
	})
	const dateRange = css({
		display: "flex",
		justifyContent: "space-between",
	})
	const date = css({
		width: "48%",
	})
	const transportContainer = css({
		display: "flex",
		justifyContent: "space-between",
		marginTop: "10px",
		marginBottom: "10px",
	})
	const transportInner = css({
		width: "48%",
		display: "flex",
		flexDirection: "column",
	})
	const buttonGroup = css({
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginTop: "10px",

		[`@media print`]: {
			display: "none",
		},
	})
	const gapManagementContainer = css({
		display: "flex",
		alignItems: "center",
		backgroundColor: inGapManagement ? theme.colors.warning50 : undefined,
		marginTop: "10px",
		padding: inGapManagement ? "5px" : undefined,
	})

	const newLine = useMutation(planLine ? fetching.mutation.ndisPlanEditLine : fetching.mutation.ndisPlanAddLine)
	const deleteLine = useMutation(fetching.mutation.ndisPlanDeleteLine)
	const planManagers = useQuery(fetching.query.getNDISPlanManagers())
	const lineItem: NDISLineItem[] = watch("lineItem")
	const [lineItemOptions, setLineItemOptions] = React.useState<NDISLineItem[]>([])
	const budget = watch("budget")
	const includesTransport = watch("includesTransport")
	const includesProviderTravel = watch("includesProviderTravel")
	const [initialLoading, setInitialLoading] = React.useState(true)

	const [lineItemDisplayKey, setLineItemDisplayKey] = React.useState<string>("")
	const debouncedSearchTerm = useDebounce(lineItemDisplayKey, 500)
	const searchKey = React.useMemo(() => debouncedSearchTerm, [debouncedSearchTerm])

	const {
		query: searchLineItems,
		payload: lineItems,
		loading: lineItemsLoading,
		error: lineItemsError,
	} = useQuery(fetching.query.getNDISLineItems(searchKey, planLine))

	React.useEffect(() => {
		if (searchKey === "") return
		searchLineItems()
	}, [searchKey, searchLineItems])

	React.useEffect(() => {
		if (lineItemsError || !lineItems) return
		// Set line items (filter out already used items from other lines)
		const items = lineItems
			? lineItems.filter((i) => !planLines || (planLine && planLine.support.lineItem.id === i.id) || !planLines.find((l) => l.supportLineItemID === i.id))
			: []
		setLineItemOptions(items)
		if (planLine) {
			setValue(
				"lineItem",
				items.filter((value) => value.id === planLine?.support.lineItem.id),
			)
		}
	}, [lineItems, lineItemsError, planLine, planLines, setValue])

	const [showDeleteModal, setShowDeleteModal] = React.useState(false)

	React.useEffect(() => {
		// on delete
		if (!deleteLine.status) return
		setShowDeleteModal(false)
		if (deleteLine.status === 200) {
			onDeleteNotify()
			onCancel()
		}
	}, [deleteLine, onCancel, onDeleteNotify])

	const onSubmit = async (data: NDISLinesFormValues) => {
		// If plan is managed by "Plan Managed" use child selection instead
		let managedByID = data.managedBy[0].id
		if (data.planManager && data.planManager.length > 0) {
			managedByID = data.planManager[0].id as string
		}

		// If activity transport budget is managed by "Plan Managed" use child selection instead
		let chargeTransportToID: string | undefined = undefined
		if (data.includesTransport) {
			if (transportManagedByChildren.length > 0 && data.transportPlanManager && data.transportPlanManager.length > 0) {
				chargeTransportToID = data.transportPlanManager[0].id
			} else {
				chargeTransportToID = data.chargeTransportTo[0].id
			}
		}

		// If provider travel budget is managed by "Plan Managed" use child selection instead
		let chargeProviderTravelToID: string | undefined = undefined
		if (data.includesProviderTravel) {
			if (providerTravelManagedByChildren.length > 0 && data.providerTravelPlanManager && data.providerTravelPlanManager.length > 0) {
				chargeProviderTravelToID = data.providerTravelPlanManager[0].id
			} else {
				chargeProviderTravelToID = data.chargeProviderTravelTo[0].id
			}
		}

		const resp = await newLine.mutate({
			planLineID: planLine ? planLine.id : undefined,
			planID: plan.id,
			lineItemID: data.lineItem[0].id,
			managedByID: managedByID,
			budget: data.budget,
			inGapManagement: data.inGapManagement,
			isincludedinutilisation: !!data.isincludedinutilisation,
			gapManagementFrom: data.inGapManagement ? data.gapManagementFrom : undefined,
			gapManagementTo: data.inGapManagement ? data.gapManagementTo : undefined,
			serviceStartDate: data.serviceStartDate,
			serviceEndDate: data.serviceEndDate,
			includesTransport: data.includesTransport,
			chargeTransportToID: chargeTransportToID,
			transportValue: data.transportValue,
			includesProviderTravel: data.includesProviderTravel,
			chargeProviderTravelToID: chargeProviderTravelToID,
			providerTravelValue: data.providerTravelValue,
		})
		if (resp.status === 200 && resp.payload) {
			onSuccess(resp.payload)
		}
	}

	// Reset transport related values, when includesTransport/includesProviderTravel is unchecked
	React.useEffect(() => {
		// check initialLoading to prevent values from being reset while the edit state is being loaded in.
		if (initialLoading) return
		if (!includesTransport) {
			setValue("chargeTransportTo", undefined)
			setValue("transportPlanManager", undefined)
			setValue("transportValue", undefined)
		}
		if (!includesProviderTravel) {
			setValue("chargeProviderTravelTo", undefined)
			setValue("providerTravelPlanManager", undefined)
			setValue("providerTravelValue", undefined)
		}
	}, [includesTransport, includesProviderTravel, initialLoading, setValue])

	// On change of the line item, set the related travel includes to false if it does not support them
	React.useEffect(() => {
		if (initialLoading) return
		if (!lineItem || !lineItem[0]) return
		if (!lineItem[0].hasTransport) {
			setValue("includesTransport", false)
		}
		if (!lineItem[0].hasTravel) {
			setValue("includesProviderTravel", false)
		}
	}, [initialLoading, lineItem, setValue])

	const getLabel = ({ option }: any) => (
		<>
			<LabelSmall>{option?.itemNumber}</LabelSmall>
			{`${option?.itemName}`}
		</>
	)

	const selectedLineItem: NDISLineItem | undefined = lineItem && lineItem[0]
	let price = 0
	let priceError = ""
	if (selectedLineItem) {
		switch (plan.priceType) {
			case NDISPriceType.National:
				if (!selectedLineItem.priceNational) {
					priceError = "Unable to find national price"
					break
				}
				price = parseFloat(selectedLineItem.priceNational)
				break
			case NDISPriceType.Remote:
				if (!selectedLineItem.priceRemote) {
					priceError = "Unable to find remote price"
					break
				}
				price = parseFloat(selectedLineItem.priceRemote)
				break
			case NDISPriceType.VeryRemote:
				if (!selectedLineItem.priceVeryRemote) {
					priceError = "Unable to find very remote price"
					break
				}
				price = parseFloat(selectedLineItem.priceVeryRemote)
				break
		}
	}

	// Filter only root level planManagers ie. ones that don't have a parentID
	const planManagersParent = planManagers.payload?.filter((value) => !value.parentID) || []
	const watchManagedBy = watch("managedBy")
	const watchChargeTransportTo = watch("chargeTransportTo")
	const watchChargeProviderTravelTo = watch("chargeProviderTravelTo")

	// Filter planManagers that are child of currently set planManager
	const getChildren = React.useCallback(
		(planManager: Value): Value => {
			if (planManager && planManager.length > 0) {
				return planManagers.payload?.filter((value) => value.parentID === planManager[0].id && value.id !== planManager[0].id) || []
			}
			return []
		},
		[planManagers.payload],
	)

	const managedByChildren = getChildren(watchManagedBy)
	const transportManagedByChildren = getChildren(watchChargeTransportTo)
	const providerTravelManagedByChildren = getChildren(watchChargeProviderTravelTo)

	// Reset planManager value if parent managedBy changes to a value that has no children elements
	React.useEffect(() => {
		if (!watchManagedBy || watchManagedBy.length < 0 || getChildren(watchManagedBy).length < 1) {
			setValue("planManager", [])
		}
	}, [getChildren, setValue, watchManagedBy])

	// Set initial values if being used for edit, that also require waiting for lists to load
	React.useEffect(() => {
		if (!planLine) return
		if (planManagers.payload) {
			const planManager = planLine?.support.managedBy
			if (planManager && !planManager.parentID) {
				// set managedBy if planManager is a parent node
				setValue("managedBy", [planManager])
			} else {
				// if planManager is a child node, set managedBy and planManager as parent and child respectively
				setValue(
					"managedBy",
					planManagers.payload.filter((value) => value.id === planManager?.parentID),
				)
				// child plan manager
				setValue(
					"planManager",
					planManagers.payload.filter((v) => v.id === planLine.supportManagedByID),
				)
			}

			const chargeTransportTo = planLine?.transport?.managedBy
			if (chargeTransportTo) {
				if (!chargeTransportTo.parentID) {
					// set managedBy if planManager is a parent node
					setValue("chargeTransportTo", [chargeTransportTo])
				} else {
					// if planManager is a child node, set managedBy and planManager as parent and child respectively
					setValue(
						"chargeTransportTo",
						planManagers.payload.filter((value) => value.id === chargeTransportTo.parentID),
					)
					setValue(
						"transportPlanManager",
						planManagers.payload.filter((value) => value.id === chargeTransportTo.id),
					)
				}
			}

			const chargeProviderTravelTo = planLine?.travel?.managedBy
			if (chargeProviderTravelTo) {
				if (!chargeProviderTravelTo.parentID) {
					// set managedBy if planManager is a parent node
					setValue("chargeProviderTravelTo", [chargeProviderTravelTo])
				} else {
					// if planManager is a child node, set managedBy and planManager as parent and child respectively
					setValue(
						"chargeProviderTravelTo",
						planManagers.payload.filter((value) => value.id === chargeProviderTravelTo.parentID),
					)
					setValue(
						"providerTravelPlanManager",
						planManagers.payload.filter((value) => value.id === chargeProviderTravelTo.id),
					)
				}
			}
		}
	}, [planLine, planManagers.payload, setValue])

	// Warnings
	const [dateRangeWarning, setDateRangeWarning] = React.useState<string>()
	const checkDateRange = React.useCallback(
		(startDate: moment.Moment, endDate: moment.Moment) => {
			const planStart = moment(plan.planStartDate)
			const planEnd = moment(plan.planEndDate)

			if (startDate.isAfter(planEnd) || endDate.isAfter(planEnd) || startDate.isBefore(planStart) || endDate.isBefore(planStart)) {
				setDateRangeWarning("Service Dates are outside NDIS plan date range.")
			} else {
				setDateRangeWarning(undefined)
			}
		},
		[plan.planEndDate, plan.planStartDate],
	)

	// Set initial values if being used for edit
	React.useEffect(() => {
		if (planLine) {
			setValue("inGapManagement", planLine.inGapManagement)
			setValue("isincludedinutilisation", planLine.isincludedinutilisation)
			if (planLine.inGapManagement) {
				if (planLine.gapManagementFrom) {
					setValue("gapManagementFrom", planLine.gapManagementFrom)
				}
				if (planLine.gapManagementTo) {
					setValue("gapManagementTo", planLine.gapManagementTo)
				}
			}

			setValue("budget", planLine.support.budget)
			const startDate = moment(planLine.serviceStartDate)
			const endDate = moment(planLine.serviceEndDate)
			setValue("serviceStartDate", startDate.toDate())
			setValue("serviceEndDate", endDate.toDate())
			checkDateRange(startDate, endDate)

			if (planLine.transport) {
				setValue("includesTransport", true)
				setValue("transportValue", planLine.transport.budget)
			}
			if (planLine.travel) {
				setValue("includesProviderTravel", true)
				setValue("providerTravelValue", planLine.travel.budget)
			}
		}

		setInitialLoading(false)
	}, [checkDateRange, planLine, setValue])

	return (
		<ZenCard className={card}>
			{(planManagers.loading || initialLoading) && <LoadingOverlay />}
			<HeadingXSmall marginTop={0} marginBottom={"10px"}>
				{planLine ? "Edit Line Item" : "Add Line Items"}
			</HeadingXSmall>
			<div className={planInfo}>
				<FieldDisplay label="NDIS Number">
					<LabelSmall>{ndisNumber}</LabelSmall>
				</FieldDisplay>
				<FieldDisplay label="Plan Start/End">
					<LabelSmall>{`${moment(plan.planStartDate).format(ZenDateFormat)} - ${moment(plan.planEndDate).format(ZenDateFormat)}`}</LabelSmall>
				</FieldDisplay>
				{priceError && <ErrorNotification messageOrPayload={priceError} />}
				<FieldDisplay label="Price Type">
					<LabelSmall>{plan.priceType.charAt(0) + plan.priceType.slice(1).toLowerCase()}</LabelSmall>
				</FieldDisplay>
			</div>
			<form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
				<ZenSelect
					options={lineItemOptions}
					filterOptions={(options) => options}
					isLoading={lineItemsLoading}
					label={"Line Item Reference Number"}
					formRef={control}
					formName={"lineItem"}
					placeholder={"Search..."}
					labelKey="itemName"
					valueKey="id"
					inputError={errors.lineItem}
					getOptionLabel={getLabel}
					getValueLabel={getLabel}
					onInputChange={(e) => setLineItemDisplayKey(e.currentTarget.value)}
					formRules={{
						validate: {
							required: (value: Value) => (!!value && value.length === 1) || "You must select a line item",
						},
					}}
				/>
				<ZenInput
					label="Budget"
					nameRef="budget"
					type="number"
					inputError={errors.budget}
					formRef={control}
					formRules={{
						required: "Budget is required",
						pattern: {
							value: currencyRegex,
							message: "Please enter a valid budget",
						},
					}}
				/>
				<Divider style={{ backgroundColor: "transparent" }} />
				<div className={planInfo}>
					<FieldDisplay label="Hourly Rate">
						<LabelSmall>{selectedLineItem ? `$${price}` : "-"}</LabelSmall>
					</FieldDisplay>
					<FieldDisplay label="Hours in Plan">
						<LabelSmall>{selectedLineItem && budget ? `${(budget / price).toFixed(2)}` : "-"}</LabelSmall>
					</FieldDisplay>
				</div>
				<div className={dateRange}>
					<div className={date}>
						<ZenDatePicker
							formRef={control}
							label={"Service Start Date"}
							formName={"serviceStartDate"}
							inputError={errors.serviceStartDate}
							nullDefaultValue
							formRules={{
								validate: {
									required: (value: string) => {
										if (!value) return "You must select a start date"
										const startDate = moment(value)
										const endDate = moment(getValues("serviceEndDate"))
										if (startDate.isAfter(endDate)) return "Start date must be before end date"
										checkDateRange(startDate, endDate)
										return null
									},
								},
							}}
							actionOnChange={(v) => {
								const startDate = moment(v)
								// If end date is not set then use the plan end date to check that the start date is in the NDIS plan range
								const endDate = moment(getValues("serviceEndDate") ?? plan.planEndDate)

								checkDateRange(startDate, endDate)
							}}
						/>
					</div>
					<div className={date}>
						<ZenDatePicker
							formRef={control}
							label={"Service End Date"}
							formName={"serviceEndDate"}
							inputError={errors.serviceEndDate}
							nullDefaultValue
							formRules={{
								validate: {
									required: (value: string) => {
										if (!value) return "You must select an end date"
										const endDate = moment(value)
										const startDate = moment(getValues("serviceStartDate"))
										if (endDate.isBefore(startDate)) return "End date must be after start date"
										checkDateRange(startDate, endDate)
										return null
									},
								},
							}}
							actionOnChange={(v) => {
								// If start date is not set then use the plan start date to check that the end date is in the NDIS plan range
								const startDate = moment(getValues("serviceStartDate") ?? plan.planStartDate)
								const endDate = moment(v)

								checkDateRange(startDate, endDate)
							}}
						/>
					</div>
				</div>
				{dateRangeWarning && <ErrorNotification message={dateRangeWarning} kind="warning" />}
				<div className={gapManagementContainer}>
					<ZenCheckbox label="In Gap Management" formName="inGapManagement" labelPlacement="left" marginRight={"20px"} formRef={control} checked={false} />
					{inGapManagement && (
						<>
							<div style={{ marginRight: "10px" }}>
								<ZenDatePicker
									formRef={control}
									label={"From"}
									formName={"gapManagementFrom"}
									inputError={errors.gapManagementFrom}
									nullDefaultValue
									formRules={{
										validate: {
											required: (value: string) => {
												if (!value) return "You must select a start date"
												return null
											},
										},
									}}
								/>
							</div>
							<div style={{ marginLeft: "10px" }}>
								<ZenDatePicker
									formRef={control}
									label={"To"}
									formName={"gapManagementTo"}
									inputError={errors.gapManagementTo}
									nullDefaultValue
									formRules={{
										validate: {
											required: (value: string) => {
												if (!value) return "You must select an end date"
												const endDate = moment(value)
												const startDate = moment(getValues("gapManagementFrom"))
												if (endDate.isBefore(startDate)) return "End date must be after start date"
												return null
											},
										},
									}}
								/>
							</div>
						</>
					)}
				</div>
				<ZenCheckbox label="Include in Utilisation" formName={"isincludedinutilisation"} labelPlacement="left" marginRight={"20px"} formRef={control} checked={false} />
				<ZenSelect
					options={planManagersParent}
					isLoading={planManagers.loading}
					label={"Managed By"}
					formRef={control}
					formName={"managedBy"}
					placeholder={"Select..."}
					inputError={errors.managedBy}
					formRules={{
						validate: {
							required: (value: Value) => (!!value && value.length === 1) || "You must select a plan manager",
						},
					}}
				/>
				{managedByChildren.length > 0 && (
					<ZenSelect
						options={managedByChildren}
						isLoading={planManagers.loading}
						label={"Plan Manager"}
						formRef={control}
						formName={"planManager"}
						placeholder={"Select..."}
						inputError={errors.planManager}
						formRules={{
							validate: {
								required: (value: Value) => (!!value && value.length === 1) || "You must select a plan manager",
							},
						}}
					/>
				)}
				<div className={transportContainer}>
					<div className={transportInner}>
						<ZenCheckbox
							label="Includes Activity Transport"
							formName="includesTransport"
							labelPlacement="left"
							marginRight={"20px"}
							formRef={control}
							checked={false}
							disabled={!lineItem || !lineItem[0] || !lineItem[0].hasTransport}
						/>
						{!!includesTransport && (
							<>
								<ZenSelect
									options={planManagersParent}
									isLoading={planManagers.loading}
									label={"Charge transport to"}
									formRef={control}
									formName={"chargeTransportTo"}
									placeholder={"Select..."}
									inputError={errors.chargeTransportTo}
									formRules={{
										validate: {
											required: (value: Value) => (!!value && value.length === 1) || "You must select a plan manager",
										},
									}}
								/>
								{transportManagedByChildren.length > 0 && (
									<ZenSelect
										options={transportManagedByChildren}
										isLoading={planManagers.loading}
										label={"Plan Manager"}
										formRef={control}
										formName={"transportPlanManager"}
										placeholder={"Select..."}
										inputError={errors.transportPlanManager}
										formRules={{
											validate: {
												required: (value: Value) => (!!value && value.length === 1) || "You must select a plan manager",
											},
										}}
									/>
								)}
								<ZenInput
									label="Transport Value"
									nameRef="transportValue"
									type="number"
									placeholder={"Enter Value"}
									inputError={errors.transportValue}
									formRef={control}
									formRules={{
										pattern: {
											value: currencyRegex,
											message: "Please enter a valid budget",
										},
										validate: {
											required: (value: string) => !getValues("includesTransport") || value !== "" || "Budget is required",
										},
									}}
								/>
							</>
						)}
					</div>

					<div className={transportInner}>
						<ZenCheckbox
							label="Includes Provider Travel"
							formName="includesProviderTravel"
							labelPlacement="left"
							marginRight={"20px"}
							formRef={control}
							checked={false}
							disabled={!lineItem || !lineItem[0] || !lineItem[0].hasTravel}
						/>
						{!!includesProviderTravel && (
							<>
								<ZenSelect
									options={planManagersParent}
									isLoading={planManagers.loading}
									label={"Charge provider travel to"}
									formRef={control}
									formName={"chargeProviderTravelTo"}
									placeholder={"Select..."}
									inputError={errors.chargeProviderTravelTo}
									formRules={{
										validate: {
											required: (value: Value) => (!!value && value.length === 1) || "You must select a plan manager",
										},
									}}
								/>
								{providerTravelManagedByChildren.length > 0 && (
									<ZenSelect
										options={providerTravelManagedByChildren}
										isLoading={planManagers.loading}
										label={"Plan Manager"}
										formRef={control}
										formName={"providerTravelPlanManager"}
										placeholder={"Select..."}
										inputError={errors.providerTravelPlanManager}
										formRules={{
											validate: {
												required: (value: Value) => (!!value && value.length === 1) || "You must select a plan manager",
											},
										}}
									/>
								)}
								<ZenInput
									label="Provider Travel Value"
									nameRef="providerTravelValue"
									type="number"
									placeholder={"Enter Value"}
									inputError={errors.providerTravelValue}
									formRef={control}
									formRules={{
										pattern: {
											value: currencyRegex,
											message: "Please enter a valid budget",
										},
										validate: {
											required: (value: string) => !getValues("includesProviderTravel") || value !== "" || "Budget is required",
										},
									}}
								/>
							</>
						)}
					</div>
				</div>
				{newLine.error && <ErrorNotification messageOrPayload={newLine.payload} />}
				{deleteLine.error && <ErrorNotification messageOrPayload={deleteLine.payload} />}
				{planManagers.error && <ErrorNotification messageOrPayload={planManagers.payload} />}
				{lineItemsError && <ErrorNotification messageOrPayload={lineItems} />}
				<FormControl error={newLine.error}>
					<div className={buttonGroup}>
						{planLine ? (
							<ZenButton altKind="danger" marginTop="15px" onClick={() => setShowDeleteModal(true)} width="20%" type="button">
								<FontAwesomeIcon icon={["fal", "trash-alt"]} style={{ marginRight: "5px" }} />
								<span>Delete Line</span>
							</ZenButton>
						) : (
							<Spacer />
						)}

						<CancelAndSaveButtons omitCancel isLoading={newLine.loading} disabled={!!priceError} width="100%" />
					</div>
				</FormControl>
			</form>

			{showDeleteModal && (
				<ZenConfirmModal
					open={showDeleteModal}
					setOpen={setShowDeleteModal}
					loading={deleteLine.loading}
					title="Are you sure you want to delete"
					message="This line item?"
					action="Delete"
					confirm={() => planLine && deleteLine.mutate({ id: planLine.id })}
				/>
			)}
		</ZenCard>
	)
}
