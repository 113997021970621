export enum FilterBy {
	All = "All",
	Own = "Own",
	Active = "Active",
	Archived = "Archived",
	RequiresAction = "RequiresAction",
	NDIS = "NDIS",
	NDISPlanReviewPeriod = "NDISPlanReviewPeriod",
	Team = "Team",
	Organisation = "Organisation",
	OrganisationArchived = "OrganisationArchived",
	ClientWithoutIntake = "ClientWithoutIntake",
}
export enum SortBy {
	DateCreated = "DateCreated",
	DateUpdated = "DateUpdated",
	Alphabetical = "Alphabetical",
	CreatedByName = "CreatedByName",
	UpdatedByName = "UpdatedByName",
}
export enum SortDir {
	Ascending = "Ascending",
	Descending = "Descending",
}

export enum SupportAllocationAdult {
	Counselling = "Counselling",
	PeerSupport = "Peer support",
	Advocacy = "Advocacy",
	SupportGroups = "Support groups",
}

export enum SupportAllocationYouth {
	YouthCarerSupport = "Youth Carer Support",
	EarlyIntervention = "Early Intervention"//,
	//NotAppropriateForHelpingMinds = "Not Appropriate for HelpingMinds",
}

export enum RiskType {
	ClientCare = "Client Care",
	Visitor = "Visitor",
	Environmental = "Environmental",
	FinancialFunding = "Financial / Funding",
	Infrastructure = "Infrastructure",
	ServiceDelivery = "Service Delivery",
	InformationTechnology = "Information Technology",
	Management = "Management",
	LegislationCommercial = "Legislation / Commercial",
	Operational = "Operational",
	Equipment = "Equipment",
	Stakeholders = "Stakeholders",
	OSHHazard = "OSH / Hazard",
	Reputation = "Reputation",
	HumanResources = "Human Resources",
}

export enum RiskCriteria {
	StaffInpact = "Staff Impact",
	CriticalServiceDelay = "Critical Service Delay",
	FinancialLoss = "Financial Loss",
	OrganisationalObjectives = "Organisational Objectives",
	KeyPerformanceIndicators = "Key Performance Indicators",
	NonCompliance = "Non-Compliance",
	Enviroment = "Enviroment",
}
export const RiskCriteriaAcronym = (criteria: RiskCriteria): string => {
	switch (criteria) {
		case RiskCriteria.StaffInpact:
			return "SI"
		case RiskCriteria.CriticalServiceDelay:
			return "CS"
		case RiskCriteria.FinancialLoss:
			return "FL"
		case RiskCriteria.OrganisationalObjectives:
			return "OO"
		case RiskCriteria.KeyPerformanceIndicators:
			return "PI"
		case RiskCriteria.NonCompliance:
			return "NC"
		case RiskCriteria.Enviroment:
			return "EN"
	}
}

export enum NoteTypeCategory {
	Client = "CLIENT",
	SessionGeneral = "SESSION_GENERAL",
	SessionNDIS = "SESSION_NDIS",
}

export enum FormType {
	General = "GENERAL",
	WellbeingPlan = "WELLBEING_PLAN",
	IncidentAndAccidentReport = "INCIDENT_AND_ACCIDENT_REPORT",
	// following commented forms are not implemented yet
	// Referral = "Referral",
	// NDISTravel = "NDISTravel",
	// Advocacy = "Advocacy",
	HazardRiskIdentification = "HAZARD_RISK_IDENTIFICATION",
	K10 = "K10",
	RiskAssessmentVisitation = "RISK_ASSESSMENT_VISITATION",
	RiskAssessment = "RISK_ASSESSMENT",
	CarersStar = "CARERS_STAR",
	YouthStar = "YOUTH_STAR",
	DASS21 = "DASS21",
	SafetyPlan = "SAFETY_PLAN",
	Intake = "INTAKE",
}

export const FormTypeName = (type: FormType): string => {
	switch (type) {
		case FormType.WellbeingPlan:
			return "Wellbeing Plan"
		case FormType.IncidentAndAccidentReport:
			return "Incident and Accident Report"
		// following commented forms are not implemented yet
		// case FormType.Referral:
		// 	return "Referral"
		// case FormType.NDISTravel:
		// 	return "NDIS Travel"
		// case FormType.Advocacy:
		// 	return "Advocacy"
		case FormType.HazardRiskIdentification:
			return "Hazard / Risk Identification"
		case FormType.K10:
			return "K10"
		case FormType.RiskAssessmentVisitation:
			return "Outreach Visitation Risk Assessment"
		case FormType.RiskAssessment:
			return "Risk Assessment"
		case FormType.CarersStar:
			return "Carers Star"
		case FormType.YouthStar:
				return "Youth Star"
		case FormType.DASS21:
			return "DASS21"
		case FormType.SafetyPlan:
			return "Safety Plan"
		case FormType.Intake:
			return "Intake"
	}
	return "General"
}

export const FormTypeURL = (type: FormType): string => {
	const name = FormTypeName(type)
	return name.toLowerCase().replace(" / ", "~").replace(/ /g, "_")
}

export enum IntakeType {
	Adult = "Adult",
	Youth = "Youth",
	NDIS = "NDIS",
}
export const daysOfWeek = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"]

export const minimumAgeMonths = 18 // Not 18 years - HelpingMinds visits primary schools.

export enum ReviewStatus {
	Pending = "PENDING", // Not ready for payroll, still going through review process
	Approved = "APPROVED", // Ready to be paid, has gone through all the checks etc.
	Declined = "DECLINED", // Will not be approved in current state. Something needs to be fixed etc.
}

// MileageClaimStatus is determined from a combination of the claims ReviewStatus as well as the status of marked as ready,
// checked, or marked as complete.
export enum MileageClaimStatus {
	Draft = "DRAFT", // Review status is pending, but not marked as ready. Must be marked ready before it can be approved.
	Pending = "PENDING", // Review status is pending and has been marked as ready and can be checked
	Checked = "CHECKED", // Review status is pending, and has been checked
	Approved = "APPROVED", // Ready to be paid, has gone through all the checks etc.
	Declined = "DECLINED", // Will not be approved in current state. Something needs to be fixed etc.
	Complete = "COMPLETE", // completedAt is not null. Would expect review status to be "APPROVED"
}

// TimesheetStatus is determined from a combination of the claims ReviewStatus as well as the status of marked as ready
// or marked as complete.
export enum TimesheetStatus {
	Draft = "DRAFT", // Review status is pending, but not marked as ready. Must be marked ready before it can be approved.
	Pending = "PENDING", // Review status is pending, has been marked as ready. Ready for review.
	Checked = "CHECKED", // Review status is pending, and has been checked
	Approved = "APPROVED", // Ready to be paid, has gone through all the checks etc.
	Declined = "DECLINED", // Will not be approved in current state. Something needs to be fixed etc.
	Complete = "COMPLETE", // completedAt is not null. Would expect review status to be "APPROVED"
}

export enum SupportType {
	DidNotAttend = "Did not attend",
	CancelWithIn24HRS = "Cancellation (24 HRS notice)",
}

export enum NDISPlanStatus {
	Inactive = "INACTIVE",
	Active = "ACTIVE",
}

export enum NDISLineItemUnits {
	Each = "EACH",
	Year = "YEAR",
	Hour = "HOUR",
}

export enum NDISLineItemType {
	Support = "SUPPORT",
	Travel = "TRAVEL",
	Transport = "TRANSPORT",
	GeneralTransport = "GENERAL_TRANSPORT",
}

export enum NDISLineItemVariant {
	Saturday = "SATURDAY",
	Sunday = "SUNDAY",
	Holiday = "HOLIDAY",
	Standard = "STANDARD",
	Evening = "EVENING",
	Night = "NIGHT",
	Value = "VALUE",
}

export enum NDISPriceType {
	National = "NATIONAL",
	Remote = "REMOTE",
	VeryRemote = "VERY_REMOTE",
}

export enum NDISFileType {
	NDISPlan = "NDIS_PLAN",
	ServiceAgreement = "SERVICE_AGREEMENT",
}

export enum ClientRelationshipType {
	Parent = "Parent",
	Guardian = "Guardian",
	Sibling = "Sibling ",
	Daughter = "Daughter",
	Son = "Son",
	Grandparent = "Grandparent",
	Child = "Child",
	Partner = "Partner",
}

export enum StarChartLabel {
	HowYouFeel = "how you feel",
	Finances = "finances",
	Work = "work",
	health = "health",
	TheCaringRole = "the caring role",
	ManagingAtHouse = "managing at house",
	TimeForYourself = "time for yourself",
}
export enum YouthStarChartLabel {
	InterestAndActivities = "interest and activities",
	HopesAndDreams = "hopes and dreams",
	HealthAndWellBeing = "health and well being",
	EducationAndWork = "education and work",
	Communicating = "communicating",
	ChoicesAndBehavior = "choices and behavior",
}

export enum AttendanceStatus {
	Attended = "ATTENDED",
	Cancelled = "CANCELLED",
	ShortNoticeCancellation = "SHORT_NOTICE_CANCELLATION",
	DNA = "DNA",
	EndedEarly = "ENDED_EARLY",
}

export enum RateVariant {
	Saturday = "SATURDAY",
	Sunday = "SUNDAY",
	Holiday = "HOLIDAY",
	Standard = "STANDARD",
	Evening = "EVENING",
	Night = "NIGHT",
}

export enum EmploymentStatus {
	FullTime = "FULL_TIME",
	PartTime = "PART_TIME",
	Casual = "CASUAL",
}

export enum SessionClientType {
	Youth_Support = "YOUTH_SUPPORT",
	Adult_Support = "ADULT_SUPPORT",
	Family_Support = "FAMILY_SUPPORT",
}

export enum FilterByOptions {
	// AllClients = "ALL_CLIENTS",
	ActiveClientsOnly = "ACTIVE_CLIENTS_ONLY",
	ArchivedClientsOnly = "ARCHIVE_CLIENTS_ONLY",
	Sessions = "SESSIONS",
}

export enum PronounSection {
	HeHimHis = "HE_HIM_HIS",
	SheHer = "SHE_HER",
	TheyThem = "THEY_THEM",
}