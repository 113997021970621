import * as React from "react";
import { useStyletron } from "baseui";
import { Value, Option } from "baseui/select";
import { LabelLarge } from "baseui/typography";
import { useQuery } from "react-fetching-library";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { fetching } from "../../../../fetching";
import { BasicName } from "../../../../types/types";
import { CancelAndSaveButtons } from "../../../cancelSaveButtons";
import { ErrorNotification } from "../../../errorBox";
import { ZenCheckbox } from "../../../zenComponents/zenCheckboxList";
import { ZenInput, ZenTextArea } from "../../../zenComponents/zenInput";
import { ZenSelect } from "../../../zenComponents/zenSelectBox";
import { IntakeScrollDiv } from "../intakeComponent";
import { CommonIntakeFormData } from "./detail";
import { IntakeType, SupportAllocationAdult, SupportAllocationYouth } from "../../../../types/enums"; 
import { ZenCheckList } from "../../../zenComponents/zenCheckboxList";

export enum PreferredLocation {
    Office = "Office",
    School = "School",
    OtherLocation = "Other Location",
}

// Define the form data type here
type FormData = {
	referralAccepted: boolean;
	referralPending: boolean;
	referralIneligible: boolean;
	preferredLocationOption: Value;
	supportAllocationOtherDetails?: string;
	office?: string;
	preferredLocation?: string;
	[key: string]: any; 
  };
  

export const ActionPlan = (props: CommonIntakeFormData) => {
    const { isReadOnly, validationTriggered, onSubmit: finalSubmission, intakeType } = props;
    const [css] = useStyletron();
    const history = useHistory();

    const { watch, control, trigger, setValue, getValues, formState: { errors } } = useFormContext<FormData>();


    // Watch necessary form fields
    const referralAccepted = watch("referralAccepted");
    const referralPending = watch("referralPending");
    const referralIneligible = watch("referralIneligible");
    const preferredLocationOption = watch("preferredLocationOption");

    // Check if "Other" is selected in support allocation
    const otherSelected = watch("other", false);


    // Define allocation options with the "Other" option included
    const allocationOptions = [
        ...(intakeType === IntakeType.Adult
            ? Object.entries(SupportAllocationAdult).map<BasicName>((sa) => ({ id: sa[0], name: sa[1] }))
            : Object.entries(SupportAllocationYouth).map<BasicName>((sa) => ({ id: sa[0], name: sa[1] }))),
        { id: "other", name: "Other" }, // Adding "Other" option
    ];

    // Office options
    const [officeOptions, setOfficeOptions] = React.useState<Option[]>([]);
    const officeData = useQuery<BasicName[]>(fetching.query.getOfficeAll());
    React.useEffect(() => {
        if (officeData.loading || !officeData.payload) return;
        setOfficeOptions(officeData.payload.map<Option>((o) => ({ label: o.name, id: o.id })));
    }, [officeData.payload, officeData.loading]);

    const validation = React.useCallback(async () => {
        const checklist: string[] = [];
        if (preferredLocationOption && preferredLocationOption.length > 0) {
            switch (preferredLocationOption[0].id) {
                case PreferredLocation.Office:
                    checklist.push("office");
                    break;
                case PreferredLocation.School:
                case PreferredLocation.OtherLocation:
                    checklist.push("preferredLocation");
                    break;
            }
            const isValid = await trigger(checklist);
            return isValid;
        }
        return true;
    }, [preferredLocationOption, trigger]);

    React.useEffect(() => {
        if (validationTriggered) validation();
    }, [validationTriggered, validation]);

    const onSubmit = async () => {
        const isValid = await validation();
        if (!isValid) return;
        if (finalSubmission) finalSubmission();
    };

    const container = css({
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "white",
        flexDirection: "row",
    });
    const formStyle = css({
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "25px",
        paddingTop: "25px",
    });
    const body = css({
        width: "100%",
        height: "95%",
    });

    return (
        <div className={container}>
            <form autoComplete="off" className={formStyle}>
                <div className={body}>
                    <LabelLarge marginBottom="20px">Action Plan</LabelLarge>
                    {officeData.error && <ErrorNotification messageOrPayload={officeData.payload} />}
                    <IntakeScrollDiv>
                        {/* Referral options */}
                        <ZenCheckbox marginBottom="8px" label="Referral Accepted" formName="referralAccepted" fontSize="medium" formRef={control} disabled={isReadOnly} />
                        {referralAccepted && (
                            <>
                                <ZenCheckbox
                                    disabled={isReadOnly}
                                    marginLeft="8px"
                                    marginBottom="8px"
                                    label="Book session"
                                    formName="bookSession"
                                    fontSize="medium"
                                    formRef={control}
                                />
                                <ZenCheckbox
                                    disabled={isReadOnly}
                                    marginLeft="8px"
                                    label="Client Pack Issued"
                                    formName="clientPackIssued"
                                    fontSize="medium"
                                    formRef={control}
                                />
                                <ZenTextArea
                                    disabled={isReadOnly}
                                    marginLeft="8px"
                                    label="Referral Accepted Note"
                                    formRef={control}
                                    nameRef="referralAcceptedNote"
                                    getValues={getValues}
                                />
                            </>
                        )}

                        <ZenCheckbox marginBottom="8px" label="Referral Pending" formName="referralPending" fontSize="medium" formRef={control} disabled={isReadOnly} />
                        {referralPending && (
                            <>
                                <ZenCheckbox
                                    marginLeft="8px"
                                    marginBottom="8px"
                                    label="Wait list pending team member availability"
                                    formName="pendingTeamMemberAvailability"
                                    fontSize="medium"
                                    formRef={control}
                                    disabled={isReadOnly}
                                />
                                <ZenCheckbox
                                    marginLeft="8px"
                                    marginBottom="8px"
                                    label="Take to HelpingMinds case review meeting."
                                    formName="helpingMindsCaseReviewMeeting"
                                    fontSize="medium"
                                    formRef={control}
                                    disabled={isReadOnly}
                                />
                                <ZenCheckbox disabled={isReadOnly} marginLeft="8px" label="Intake Incomplete" formName="intakeIncomplete" fontSize="medium" formRef={control} />
                                <ZenTextArea
                                    disabled={isReadOnly}
                                    marginLeft="8px"
                                    label="Referral Pending Note"
                                    formRef={control}
                                    nameRef="referralPendingNote"
                                    getValues={getValues}
                                />
                            </>
                        )}

                        <ZenCheckbox
                            marginBottom="8px"
                            label="Referral Ineligible"
                            formName="referralIneligible"
                            fontSize="medium"
                            formRef={control}
                            disabled={isReadOnly}
                        />
                        {referralIneligible && (
                            <>
                                <ZenCheckbox
                                    disabled={isReadOnly}
                                    marginLeft="8px"
                                    label="Warm referral required"
                                    formName="warmReferralRequired"
                                    fontSize="medium"
                                    formRef={control}
                                />
                                <ZenTextArea
                                    disabled={isReadOnly}
                                    getValues={getValues}
                                    marginLeft="8px"
                                    label="Referral Ineligible Note"
                                    formRef={control}
                                    nameRef="referralIneligibleNote"
                                />
                            </>
                        )}

                        <ZenCheckbox
                            disabled={isReadOnly}
                            label="Referred to LifeLine Phone: 13 11 14"
                            formName="referredToLifeLinePhone"
                            fontSize="medium"
                            formRef={control}
                        />

						{/* Support Allocation section moved under Referral options */}
						<LabelLarge marginTop="8px">Support Allocation</LabelLarge>
						<ZenCheckList
						disabled={isReadOnly}
						formRef={control}
						label="Select Support Options"
						list={allocationOptions}
						maxHeight="180px"
						overflow="auto"
						/>

						{/* "Other" option conditional text input */}
						{otherSelected && (
						<ZenTextArea
							disabled={isReadOnly}
							getValues={getValues}
							label="Other (please specify)"
							formRef={control}
							nameRef="supportAllocationOtherDetails"
							placeholder="Enter details"
						/>
						)}

                        {/* Remaining form elements */}
                        <LabelLarge marginTop="20px" marginBottom="8px">
                            Appointment details
                        </LabelLarge>
                        <ZenSelect
                            disabled={isReadOnly}
                            label="Preferred Location"
                            formName="preferredLocationOption"
                            formRef={control}
                            options={Object.values(PreferredLocation).map((pl) => ({ id: pl, label: pl }))}
                        />
                        {preferredLocationOption && preferredLocationOption.length > 0 && (
                            <>
                                {preferredLocationOption[0].id === PreferredLocation.Office && (
                                    <ZenSelect
                                        disabled={isReadOnly}
                                        label="Please Specify"
                                        formName="office"
                                        formRef={control}
                                        inputError={errors.office}
                                        formRules={{
                                            validate: {
                                                required: (value: Value) => (!!value && value.length > 0) || "office is required",
                                            },
                                        }}
                                        options={officeOptions}
                                    />
                                )}
                                {preferredLocationOption[0].id === PreferredLocation.School && (
                                    <>
                                        <ZenInput
                                            disabled={isReadOnly}
                                            label="Please Specify"
                                            nameRef="preferredLocation"
                                            formRef={control}
                                            formRules={{ required: "Detail are required" }}
                                            inputError={errors.preferredLocation}
                                            placeholder="Enter details for school name, contact person (optional), contact number (optional)."
                                        />
                                        <ZenCheckbox
                                            disabled={isReadOnly}
                                            marginTop="10px"
                                            label="Consent to Counselling form posted/emailed"
                                            formName="sentCounsellingForm"
                                            formRef={control}
                                        />
                                    </>
                                )}
                                {preferredLocationOption[0].id === PreferredLocation.OtherLocation && (
                                    <ZenInput
                                        disabled={isReadOnly}
                                        label="Please Specify"
                                        nameRef="preferredLocation"
                                        formRef={control}
                                        formRules={{ required: "Detail are required" }}
                                        inputError={errors.preferredLocation}
                                        placeholder="Enter details"
                                    />
                                )}
                            </>
                        )}
                        {intakeType === IntakeType.Adult && (
                            <ZenTextArea
                                disabled={isReadOnly}
                                label="Appointment details"
                                nameRef="appointmentDetail"
                                formRef={control}
                                placeholder="Enter details"
                                initialHeight={180}
                                getValues={getValues}
                            />
                        )}
                        <ZenTextArea
                            disabled={isReadOnly}
                            getValues={getValues}
                            label="Additional Comments"
                            formRef={control}
                            nameRef="additionalActionPlanComment"
                            fontSize={13}
                        />
                    </IntakeScrollDiv>
                </div>
                {!isReadOnly && <CancelAndSaveButtons cancelLabel="Back" cancelFn={() => history.goBack()} saveLabel="Next" saveFn={onSubmit} />}
            </form>
        </div>
    );
};
