import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { Avatar } from "baseui/avatar"
import { Block } from "baseui/block"
import { FormControl } from "baseui/form-control"
import { Option, Select, SelectProps, Value } from "baseui/select"
import { LabelMedium, LabelSmall } from "baseui/typography"
import { useQuery } from "react-fetching-library"
import { Control, Controller, FieldError } from "react-hook-form"
import { FieldValues } from "react-hook-form/dist/types/form"
import { PortalContainer } from "../../controllers/portal"
import { fetching } from "../../fetching"
import { useDebounce } from "../../helpers/utils"
import { ZenTheme } from "../../themeOverrides"
import { FilterBy } from "../../types/enums"
import { Address, Client, SortOrder, Timezone, TZString, UserDetail } from "../../types/types"
import { ErrorNotification } from "../errorBox"

interface ZenSelectProps extends SelectProps {
	label?: string
	formName: string
	formRef: any
	formRules?: any
	inputError?: any
	getValues?: () => any
	actionOnChange?: (value: Value) => void
	rootHight?: string
	paddingBottom?: string
}

// ZenSelect: basic select box
export const ZenSelect = (props: ZenSelectProps) => {
	const [css, theme] = useStyletron()
	const { label, formName, formRef, formRules, inputError, actionOnChange, value, getValues, disabled, rootHight, paddingBottom, overrides, ...rest } = props

	const displayMessage = css({
		display: "flex",
		flexDirection: "column",
		width: "100%",
		height: "fit-content",
		marginBottom: "8px",
	})
	if (disabled && getValues && getValues()[`${formName}`])
		return (
			<div className={displayMessage}>
				{label ? <LabelSmall marginBottom="2px">{label}</LabelSmall> : ""}
				<LabelSmall
					marginLeft="16px"
					overrides={{
						Block: {
							style: {
								whiteSpace: "pre-line",
								color: ZenTheme.colors.primaryGrey,
							},
						},
					}}
				>
					{getValues()[`${formName}`].length > 0 ? getValues()[`${formName}`][0].label : ""}
				</LabelSmall>
			</div>
		)
	return (
		<FormControl
			label={<LabelSmall>{label}</LabelSmall>}
			error={inputError?.message || ""}
			overrides={{
				ControlContainer: {
					style: {
						marginBottom: 0,
					},
				},
			}}
		>
			<Controller
				name={formName}
				control={formRef}
				defaultValue={value || []}
				rules={formRules}
				render={({ value, name, onChange }) => (
					<Select
						{...rest}
						multi // Ensures multi-selection is enabled
						id={name}
						disabled={disabled}
						onChange={({ value }) => {
							if (actionOnChange) actionOnChange(value)
							onChange(value)
						}}
						value={value || []}
						overrides={{
							Root: {
								style: {
									height: rootHight,
								},
							},
							ControlContainer: {
								style: ({ $disabled }) => ({
									backgroundColor: "transparent",
									borderLeftWidth: 0,
									borderTopWidth: 0,
									borderBottomWidth: $disabled ? 0 : "2px",
									borderBottomColor: theme.colors.black,
									borderRightWidth: 0,
								}),
							},
							ValueContainer: {
								style: {
									paddingBottom: paddingBottom ? paddingBottom : rootHight ? 0 : undefined,
								},
							},
							Dropdown: {
								style: {
									maxHeight: "calc(min(200px, 60vh))",
								},
							},
						}}
					/>
				)}
			/>
		</FormControl>
	)
}

interface ZenDisplaySelectProps extends SelectProps {
	label: string
	formName: string
	formRef: any
	formRules?: any
	excludedID?: string[]
	inputError?: FieldError
	defaultValue?: Value
	actionOnChange?: (v: Value) => void
	width?: string
	options: Option[]
	loading: boolean
	delayedQuery?: (s: string) => void
	testText?: string
	marginTop?: string
	marginBottom?: string
}

// ZenDisplaySelect select picker with avatar and label
export const ZenDisplaySelect = (props: ZenDisplaySelectProps) => {
	const { label, formName, formRef, formRules, inputError, defaultValue, actionOnChange, width, options, loading, delayedQuery, ...rest } = props

	const [css] = useStyletron()
	const container = css({
		width: width || "unset",
	})

	return (
		<div className={container}>
			<FormControl
				label={<LabelSmall>{label}</LabelSmall>}
				error={inputError?.message || ""}
				overrides={{
					ControlContainer: {
						style: {
							opacity: props.disabled ? 0.5 : 1,
						},
					},
				}}
			>
				<Controller
					name={formName}
					control={formRef}
					rules={formRules}
					defaultValue={defaultValue || []}
					render={({ value, name, onChange }) => {
						return (
							<Select
								{...rest}
								id={name}
								searchable
								onChange={(s) => {
									if (actionOnChange) actionOnChange(s.value)
									onChange(s.value)
								}}
								value={value || []}
								placeholder={rest.placeholder || ""}
								labelKey="label"
								valueKey="id"
								getOptionLabel={DisplaySelectCard}
								getValueLabel={DisplaySelectCard}
								onInputChange={delayedQuery ? (e) => delayedQuery(e.currentTarget.value) : undefined}
								options={options}
								filterOptions={(v) => v}
								overrides={{
									Root: {
										style: {
											backgroundColor: "transparent",
										},
									},
									ControlContainer: {
										style: ({ $disabled }) => ({
											backgroundColor: "transparent",
											borderTopWidth: 0,
											borderLeftWidth: 0,
											borderRightWidth: 0,
											borderBottomWidth: $disabled ? 0 : "2px",
											borderBottomStyle: "solid",
											borderBottomColor: "black",
											minHeight: "48px",
										}),
									},
									Tag: {
										props: {
											overrides: {
												Root: {
													style: {
														height: "fit-content",
														backgroundColor: "white",
													},
												},
												Text: {
													style: {
														maxWidth: "fit-content",
													},
												},
												ActionIcon: {
													style: {
														color: "black",
														marginBottom: "10px",
													},
												},
											},
										},
									},
									ValueContainer: {
										style: {
											paddingBottom: 0,
											paddingTop: 0,
										},
									},
									Dropdown: {
										style: {
											display: !loading && options.length > 0 ? "block" : "none",
											maxHeight: "calc(min(250px, 60vh))",
										},
									},
									SelectArrow: {
										style: {
											display: "none",
										},
									},
								}}
							/>
						)
					}}
				/>
			</FormControl>
		</div>
	)
}

export const DisplaySelectCard = ({ option }: any) => {
	const department = option?.department && option?.department.name
	const firstName = option?.firstName
	const lastName = option?.lastName
	const label = option.label || `${firstName} ${lastName}`
	return (
		<Block display="flex" alignItems="center" justifyContent="space-between">
			<Block display="flex" alignItems="center">
				<Avatar
					name={label}
					overrides={{
						Root: {
							style: {
								minWidth: ZenTheme.sizing.scale1000,
							},
						},
					}}
					src={option?.avatarURL || ""}
				/>
				<LabelMedium marginLeft="8px">{label}</LabelMedium>
			</Block>
			<div>
				{department && <Block marginLeft="5px">{department}</Block>}
				{option.isOrganisation && (
					<Block marginLeft="5px">
						<FontAwesomeIcon title="Organisation" icon={["fal", "users"]} />
					</Block>
				)}
			</div>
		</Block>
	)
}

interface ZenPlaceSelectProps extends SelectProps {
	label: string
	formName: string
	formRef: any
	formRules?: any
	paddingBottom?: string
	inputError?: FieldError
	actionOnChange?: (v: Value) => void
	customLabel?: React.ReactNode
}

export const ZenPlaceSelect = (props: ZenPlaceSelectProps) => {
	const [, theme] = useStyletron()

	const { label, formName, formRef, formRules, value, inputError, actionOnChange, placeholder, customLabel, ...rest } = props

	const [searchKey, setSearchKey] = React.useState<string>("")
	const [placeOptions, setPlaceOptions] = React.useState<Option[]>([])

	const placePredictionData = useQuery<Address[]>(fetching.query.getPlacePredictions(searchKey), false)

	const [displayKey, setDisplayKey] = React.useState<string>("")
	const debouncedSearchTerm = useDebounce(displayKey, 500)
	React.useEffect(() => setSearchKey(debouncedSearchTerm), [debouncedSearchTerm])

	React.useEffect(() => {
		if (searchKey === "") return
		placePredictionData.query().then((resp) => {
			if (resp.error || !resp.payload) return
			setPlaceOptions(resp.payload.map<Option>((p) => ({ label: p.fullAddress, id: p.placeID, displayName: p.displayName })))
		})
	}, [searchKey]) // eslint-disable-line react-hooks/exhaustive-deps

	if (placePredictionData.error) return <ErrorNotification messageOrPayload={placePredictionData.payload} />

	return (
		<FormControl
			label={customLabel ? customLabel : <LabelSmall>{label}</LabelSmall>}
			error={inputError?.message || ""}
			overrides={{
				ControlContainer: {
					style: {
						marginBottom: "3px",
					},
				},
			}}
		>
			<Controller
				name={formName}
				control={formRef}
				rules={formRules}
				defaultValue={value || []}
				render={({ value, name, onChange }) => (
					<Select
						{...rest}
						id={name}
						searchable
						onChange={(s) => {
							if (actionOnChange) actionOnChange(s.value)
							onChange(s.value)
						}}
						labelKey="label"
						valueKey="id"
						getOptionLabel={DisplayPlaceCard}
						value={value || []}
						placeholder={placeholder}
						onInputChange={(e) => setDisplayKey(e.currentTarget.value)}
						options={placeOptions}
						filterOptions={(v) => v}
						isLoading={placePredictionData.loading}
						overrides={{
							Root: {
								style: {
									backgroundColor: "transparent",
								},
							},
							ControlContainer: {
								style: {
									backgroundColor: "transparent",
									borderLeftWidth: 0,
									borderTopWidth: 0,
									borderBottomWidth: "2px",
									borderBottomColor: theme.colors.black,
									borderRightWidth: 0,
									paddingBottom: "10px",
								},
							},
							ValueContainer: {
								style: {
									paddingBottom: 0,
									paddingTop: 0,
								},
							},
							Dropdown: {
								style: {
									display: placeOptions.length === 0 ? "none" : "block",
								},
							},
							SelectArrow: {
								style: {
									display: "none",
								},
							},
						}}
					/>
				)}
			/>
		</FormControl>
	)
}

export const DisplayPlaceCard = ({ option }: any) => {
	return (
		<Block display="flex" flexDirection="column">
			{option.displayName && <div>{option.displayName}</div>}
			<Block display="flex" alignItems="center">
				<LabelSmall>{option.label}</LabelSmall>
			</Block>
		</Block>
	)
}

// Used for client/worker select picker
interface ZenPersonSelectProps extends SelectProps {
	label: string
	formName: string
	formRef: Control<FieldValues>
	formRules?: any
	excludedID?: string[]
	inputError?: FieldError
	defaultValue?: UserDetail[] | Client[]
	actionOnChange?: (v: Value) => void
	width?: string
	marginTop?: string
	marginBottom?: string
}

interface CommonArrayType {
	id: string
	firstName: string
	lastName: string
}

export const ZenUserSelect = (props: ZenPersonSelectProps) => {
	const { excludedID, label, formName, formRef, formRules, inputError, defaultValue, width, actionOnChange, placeholder, ...rest } = props
	const [searchKey, setSearchKey] = React.useState<string>("")
	const [userOptions, setUserOptions] = React.useState<Option[]>([])

	const { payload, loading, error } = useQuery<{ users: UserDetail[]; total: number }>(
		fetching.query.getManyUsers({
			search: {
				filterBy: FilterBy.Active,
				search: searchKey || "",
				sortBy: "Name",
				sortDir: SortOrder.Ascending,
			},
			limit: 10,
			offset: 0,
			excludedID,
		}),
	)

	const [displayKey, setDisplayKey] = React.useState<string>("")
	const debouncedSearchTerm = useDebounce(displayKey, 500)
	React.useEffect(() => setSearchKey(debouncedSearchTerm), [debouncedSearchTerm])

	React.useEffect(() => {
		if (loading || !payload || error) return
		setUserOptions(payload.users.map<Option>((u) => ({ ...u, label: `${u.firstName} ${u.lastName}` })))
	}, [payload, loading, error])

	if (error) return <ErrorNotification messageOrPayload={payload} />

	return (
		<ZenDisplaySelect
			{...rest}
			label={label || "Worker"}
			placeholder={placeholder === undefined ? "Select Worker" : placeholder}
			formName={formName || "Worker"}
			formRef={formRef}
			formRules={formRules}
			excludedID={excludedID}
			inputError={inputError}
			defaultValue={defaultValue ? (defaultValue as CommonArrayType[]).map((dv) => ({ ...dv, id: dv.id, label: `${dv.firstName} ${dv.lastName} ` })) : []}
			actionOnChange={actionOnChange}
			width={width}
			options={userOptions}
			loading={loading}
			delayedQuery={setDisplayKey}
		/>
	)
}

interface ZenClientSelectProps extends ZenPersonSelectProps {
	isOrganisation?: boolean
	/** If true; will display all clients, even clients the current worker isn't assigned. */
	showAllClients?: boolean
	ndisOnly?: boolean
	customError?: string
}
export const ZenClientSelect = (props: ZenClientSelectProps) => {
	const {
		excludedID,
		label,
		formName,
		formRef,
		formRules,
		inputError,
		defaultValue,
		actionOnChange,
		width,
		placeholder,
		options,
		isOrganisation,
		ndisOnly,
		customError,
		...rest
	} = props
	const [searchKey, setSearchKey] = React.useState<string>("")
	const [clientOptions, setClientOptions] = React.useState<Option[]>([])

	const { payload, loading, error } = useQuery<{ clients: Client[]; total: number }>(
		fetching.query.getClientMany({
			search: {
				filterBy: ndisOnly ? FilterBy.NDIS : FilterBy.Active,
				sortBy: "Name",
				search: searchKey || "",
				sortDir: SortOrder.Ascending,
			},
			limit: 10,
			offset: 0,
			excludedID,
			isOrganisation,
		}),
		!options,
	)

	React.useEffect(() => {
		if (!options) return
		setClientOptions(
			(options as Option[]).filter((o: Option) => {
				if (!o.id || !excludedID) return true
				return !excludedID.includes(o.id.toString())
			}),
		)
	}, [options, searchKey, excludedID]) // eslint-disable-line react-hooks/exhaustive-deps

	const [displayKey, setDisplayKey] = React.useState<string>("")
	const debouncedSearchTerm = useDebounce(displayKey, 500)
	React.useEffect(() => setSearchKey(debouncedSearchTerm), [debouncedSearchTerm])

	React.useEffect(() => {
		if (loading || !payload || !payload.clients) return
		if (payload.clients.length === 0) {
			// Fixes a situation in the travel form
			// Where a worker with one client would be presented with that one client as both the
			// start client and destination client.
			// Issue [GM-B-25] https://trello.com/c/dEuodaPo/160-gm-b-25-worker-travel-create
			setClientOptions([])
		}

		setClientOptions(payload.clients.map<Option>((c) => ({ ...c, label: `${c.firstName} ${c.lastName}` })))
	}, [payload, loading])

	const selected: Option[] = formRef.getValues<string, Option[]>(formName) || []

	if (error) return <ErrorNotification messageOrPayload={payload} />

	return (
		<>
			<ZenDisplaySelect
				{...rest}
				placeholder={placeholder || "Select Client"}
				label={label || "Client"}
				formName={formName || "client"}
				formRef={formRef}
				formRules={formRules}
				excludedID={excludedID}
				inputError={inputError}
				defaultValue={defaultValue ? (defaultValue as CommonArrayType[]).map((dv) => ({ ...dv, id: dv.id, label: `${dv.firstName} ${dv.lastName}` })) : []}
				actionOnChange={actionOnChange}
				width={width}
				options={clientOptions}
				loading={loading}
				delayedQuery={setDisplayKey}
			/>

			{/* Show an error if there are no other clients */}
			{selected.length === 0 && !loading && payload && payload.total === 0 ? (
				customError ? (
					<ErrorNotification message={customError} />
				) : (
					<ErrorNotification message={"No other clients, please contact a supervisor to assign new clients"} />
				)
			) : null}
		</>
	)
}

interface SelectedItemCardProps {
	firstName: string
	lastName: string
	avatarUrl?: string
	clear?: () => void
	marginBottom?: string
}
export const SelectedItemCard = (props: SelectedItemCardProps) => {
	const { firstName, lastName, avatarUrl, clear, marginBottom } = props
	const [css] = useStyletron()
	const container = css({
		display: "flex",
		alignItems: "center",
		marginBottom: marginBottom || 0,
	})

	return (
		<div className={container}>
			<Avatar
				name={`${firstName} ${lastName}`}
				size="scale1000"
				src={avatarUrl || ""}
				overrides={{
					Root: {
						style: {
							minWidth: ZenTheme.sizing.scale1000,
							minHeight: ZenTheme.sizing.scale1000,
						},
					},
				}}
			/>
			<LabelMedium marginLeft="20px" marginRight="10px">{`${firstName} ${lastName}`}</LabelMedium>
			{clear && <FontAwesomeIcon icon={["fal", "times-circle"]} onClick={() => clear()} />}
		</div>
	)
}

interface ZenTimezoneSelectProps extends ZenSelectProps {
	timezone?: Timezone[]
}

export const ZenTimezoneSelect = (props: ZenTimezoneSelectProps) => {
	const { timezone, ...zenSelectProps } = props
	const { timezone: globalTimezone } = PortalContainer.useContainer()

	// initiate default value
	const defaultValue = React.useMemo(() => {
		// declare timezoneID target
		let target: Timezone | undefined = undefined
		if (timezone && timezone.length > 0) {
			// if timezone id is provided, get timezone by the provided timezone id
			target = TZString.find((tz) => tz.id === timezone[0].id)
		} else {
			// else get timezone by user's current timezone
			target = TZString.find((tz) => tz.label === globalTimezone.label)
		}

		// if target not exists, set default timezone to Perth time
		if (!target) return [TZString[0]]

		// return target timezone
		return [target]
	}, [timezone]) // eslint-disable-line react-hooks/exhaustive-deps

	return <ZenSelect {...zenSelectProps} options={TZString} value={defaultValue} label="Timezone" />
}
