import * as React from "react";
import { useStyletron } from "baseui";
import { LabelLarge } from "baseui/typography";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { CancelAndSaveButtons } from "../../../cancelSaveButtons";
import { ZenCheckbox } from "../../../zenComponents/zenCheckboxList";
import { ZenInput, ZenTextArea } from "../../../zenComponents/zenInput";
import { ZenSelect } from "../../../zenComponents/zenSelectBox";
import { intakeURLParser } from "../adult/intakeAdultBaseForm";
import { IntakeScrollDiv } from "../intakeComponent";
import { IntakeYouthProps } from "./intakeYouthBaseForm";
import { ErrorFieldTracker } from "../../../forms/errorFieldTracker";

export const IntakeYouthMentalHealthContext = (props: IntakeYouthProps) => {
  const { clientID, formID, isReadOnly, validationTriggered } = props;
  const [css] = useStyletron();
  const history = useHistory();

  const { control, errors, watch, trigger, getValues } = useFormContext();

  const hasDiagnoses = watch("hasDiagnoses");
  const hasMentalHealthConcernMember = watch("hasMentalHealthConcernMember");
  const receivingFormalSupports = watch("receivingFormalSupports");

  const onSubmit = async () => {
    // validate fields
    if (!(await validation())) return;

    // route to next page
    history.push(intakeURLParser(`/portal/clients/${clientID}/intake/youth/relationship`, formID));
  };

  const validation = React.useCallback(async () => {
    const checklist = [];
    if (hasDiagnoses) checklist.push("youngPersonMentalHealth");
    if (hasMentalHealthConcernMember) checklist.push("relationship", "mentalHealthDiagnosisConcern");
    if (receivingFormalSupports) checklist.push("formalSupportDetail");

    if (checklist.length === 0) return true;
    const isValid = await trigger(checklist);
    return isValid;
  }, [hasDiagnoses, hasMentalHealthConcernMember, receivingFormalSupports, trigger]);

  React.useEffect(() => {
    if (validationTriggered) validation();
  }, [validationTriggered, validation]);

  const container = css({
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "white",
    flexDirection: "row",
  });
  const formStyle = css({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    paddingBottom: "25px",
    paddingTop: "25px",
  });
  const body = css({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
  });
  const subForm = css({
    padding: "20px",
  });

  return (
    <div className={container}>
      <form autoComplete="off" className={formStyle}>
        <div className={body}>
          <LabelLarge>Mental Health Context</LabelLarge>
          <IntakeScrollDiv>
            <div className={subForm}>
              <ZenTextArea
                disabled={isReadOnly}
                label="Any Mental Health Concerns within the Family?"
                nameRef="name"
                formRef={control}
                placeholder="Enter the name of the family member"
                initialHeight={180}
                getValues={getValues}
              />
              <ZenCheckbox
                disabled={isReadOnly}
                formName="hasMentalHealthConcernMember"
                formRef={control}
                label="More Detail"
                overrides={{ Root: { style: { marginTop: "20px" } } }}
              />
              {hasMentalHealthConcernMember && (
                <div className={subForm}>
                  <ZenInput
                    disabled={isReadOnly}
                    label="Relationship"
                    nameRef="relationship"
                    formRules={{ required: "Detail is required" }}
                    formRef={control}
                    placeholder="Enter relationship"
                    inputError={errors.relationship}
                  />
                  <ZenTextArea
                    disabled={isReadOnly}
                    label="Mental Health Diagnosis/Concern"
                    nameRef="mentalHealthDiagnosisConcern"
                    formRef={control}
                    formRules={{ required: "Detail is required" }}
                    placeholder="Mental Health Diagnosis / Concern Detail"
                    inputError={errors.mentalHealthDiagnosisConcern}
                    initialHeight={180}
                    getValues={getValues}
                  />
                </div>
              )}
              <ZenCheckbox
                formName="receivingFormalSupports"
                formRef={control}
                label="Is the youth receiving formal support?"
                disabled={isReadOnly}
                overrides={{ Root: { style: { marginTop: "20px" } } }}
              />
              {receivingFormalSupports && (
                <ZenTextArea
                  disabled={isReadOnly}
                  label="Please list relevant information"
                  nameRef="formalSupportDetail"
                  formRef={control}
                  formRules={{ required: "Detail is required" }}
                  placeholder="Enter details about receiving formal support"
                  inputError={errors.formalSupportDetail}
                  initialHeight={180}
                  getValues={getValues}
                />
              )}
              <ZenCheckbox
                formName="hasDiagnoses"
                formRef={control}
                label="Does the Young Person have any diagnosed mental health conditions/concerns?"
                disabled={isReadOnly}
                overrides={{ Root: { style: { marginTop: "20px" } } }}
              />
              {hasDiagnoses && (
                <ZenTextArea
                  disabled={isReadOnly}
                  label="Please describe"
                  nameRef="youngPersonMentalHealth"
                  formRef={control}
                  formRules={{ required: "Detail is required" }}
                  placeholder="Enter details"
                  inputError={errors.youngPersonMentalHealth}
                  initialHeight={180}
                  getValues={getValues}
                />
              )}
            </div>
            <LabelLarge>Presenting Concerns</LabelLarge>
            <div className={subForm}>
              <ZenTextArea
                disabled={isReadOnly}
                label="Background of any difficulties the young person has had to cope with:"
                nameRef="difficultiesToCopeWith"
                formRef={control}
                placeholder="i.e. Parental separation, grief or loss, transitions, developmental/health concerns"
                initialHeight={180}
                getValues={getValues}
              />
              <ZenTextArea
                disabled={isReadOnly}
                label="Current behaviours of concern:"
                nameRef="behavioursOfConcern"
                formRef={control}
                placeholder="E.g. Any significant changes in behaviour - eating, sleeping, motivation"
                initialHeight={180}
                getValues={getValues}
              />
              <ZenTextArea
                disabled={isReadOnly}
                label="What does parent/guardian think may be contributing to this?"
                nameRef="parentThought"
                formRef={control}
                placeholder="Enter details"
                initialHeight={180}
                getValues={getValues}
              />
            </div>
          </IntakeScrollDiv>
        </div>
        <ErrorFieldTracker errorIDs={Object.keys(errors)} />
        {!isReadOnly && <CancelAndSaveButtons cancelLabel="Back" cancelFn={history.goBack} saveLabel="Next" saveFn={onSubmit} />}
      </form>
    </div>
  );
};
