import * as React from "react"
import { useStyletron } from "baseui"
import { CheckboxOverrides } from "baseui/checkbox"
import { LabelLarge, LabelSmall } from "baseui/typography"
import { useFormContext } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { ZenTheme } from "../../../../themeOverrides"
import { IntakeType } from "../../../../types/enums"
import { CancelAndSaveButtons } from "../../../cancelSaveButtons"
import { ZenCheckbox } from "../../../zenComponents/zenCheckboxList"
import { ZenTextArea } from "../../../zenComponents/zenInput"
import { intakeURLParser } from "../adult/intakeAdultBaseForm"
import { IntakeScrollDiv } from "../intakeComponent"
import { CommonIntakeFormData } from "./detail"
import { ErrorFieldTracker } from "../../../forms/errorFieldTracker"
import { ZenDatePicker } from "components/zenComponents/zenTime"
import moment from "moment";


export const ChallengesForClient = (props: CommonIntakeFormData) => {
	const { clientID, intakeType, formID, withNDIS, isReadOnly, validationTriggered } = props
	const [css] = useStyletron()
	const history = useHistory()

	const { watch, trigger, control, errors, getValues } = useFormContext()

	const hasLearningCognitiveDifficulties = watch("hasLearningCognitiveDifficulties")
	const hasMentalHealthConcerns = watch("hasMentalHealthConcerns")
	const hasPhysicalDifficulties = watch("hasPhysicalDifficulties")
	const hasAccessibilityRequirements = watch("hasAccessibilityRequirements")
	const hasRiskOfFalls = watch("hasRiskOfFalls")
	const hasAllergies = watch("hasAllergies")
	const hasMedicalHistory = watch("hasMedicalHistory")
	const hasPalliativeSupportCare = watch("hasPalliativeSupportCare")

	const validation = React.useCallback(async () => {
		const checkList: string[] = []
		// generate checkList
		if (hasLearningCognitiveDifficulties) checkList.push("learningCognitiveDifficulties")
		if (hasMentalHealthConcerns) checkList.push("mentalHealthConcerns")
		if (hasPhysicalDifficulties) checkList.push("physicalDifficulties")
		if (hasAccessibilityRequirements) checkList.push("accessibilityRequirements")
		if (hasRiskOfFalls) checkList.push("riskOfFalls")
		if (hasAllergies) checkList.push("allergies")
		if (hasMedicalHistory) checkList.push("medicalHistory")
		if (hasPalliativeSupportCare) checkList.push("palliativeSupportCare")

		const isValid = await trigger(checkList)
		return isValid
	}, [
		//
		hasMentalHealthConcerns,
		hasPalliativeSupportCare,
		hasLearningCognitiveDifficulties,
		hasMedicalHistory,
		hasAccessibilityRequirements,
		hasAllergies,
		hasPhysicalDifficulties,
		hasRiskOfFalls,
		trigger,
	])

	React.useEffect(() => {
		if (validationTriggered) validation()
	}, [validationTriggered, validation])

	const onSubmit = async () => {
		const isValid = await validation()
		if (!isValid) return
		nextPage()
	}

	const nextPage = () => {
		switch (intakeType) {
			case IntakeType.Adult:
				// for ndis version
				if (withNDIS) {
					history.push(intakeURLParser(`/portal/clients/${clientID}/intake/ndis/serviceDetail`, formID, true))
					break
				}
				history.push(intakeURLParser(`/portal/clients/${clientID}/intake/adult/clientContext`, formID))
				break
			case IntakeType.Youth:
				history.push(intakeURLParser(`/portal/clients/${clientID}/intake/youth/mentalHealthContext`, formID))
				break
		}
	}

	const container = css({
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		backgroundColor: "white",
		flexDirection: "row",
	})
	const formStyle = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		paddingBottom: "25px",
		paddingTop: "25px",
	})
	const body = css({
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		minHeight: 0,
	})

	const checkBoxStyle: CheckboxOverrides = {
		Root: {
			style: {
				marginTop: "15px",
			},
		},
	}
	return (
		<div className={container}>
			<form autoComplete="off" className={formStyle}>
				<div className={body}>
					<LabelLarge>Challenges for Client</LabelLarge>
					<IntakeScrollDiv>
						<ZenCheckbox
							label="Learning / Cognitive Difficulties"
							formName="hasLearningCognitiveDifficulties"
							formRef={control}
							disabled={isReadOnly}
							overrides={checkBoxStyle}
						/>
						{hasLearningCognitiveDifficulties && (
							<ZenTextArea
								disabled={isReadOnly}
								label="Please list any relevant information"
								nameRef="learningCognitiveDifficulties"
								formRef={control}
								inputError={errors.learningCognitiveDifficulties}
								placeholder="Enter Learning / Cognitive Difficulties"
								initialHeight={180}
								getValues={getValues}
							/>
						)}
						<ZenCheckbox label="Mental Health Concerns" formName="hasMentalHealthConcerns" formRef={control} disabled={isReadOnly} overrides={checkBoxStyle} />
						{hasMentalHealthConcerns && (
							<ZenTextArea
								disabled={isReadOnly}
								label="Please list any relevant information"
								nameRef="mentalHealthConcerns"
								formRef={control}
								inputError={errors.mentalHealthConcerns}
								placeholder="Enter mental health concerns"
								initialHeight={180}
								getValues={getValues}
							/>
						)}
	
						<ZenCheckbox label="Physical Difficulties" disabled={isReadOnly} formRef={control} formName="hasPhysicalDifficulties" overrides={checkBoxStyle} />
						{hasPhysicalDifficulties && (
							<ZenTextArea
								disabled={isReadOnly}
								label="Please list any relevant information"
								nameRef="physicalDifficulties"
								formRef={control}
								inputError={errors.physicalDifficulties}
								placeholder="Enter physical difficulties"
								initialHeight={180}
								getValues={getValues}
							/>
						)}
	
						<ZenCheckbox
							disabled={isReadOnly}
							label="Accessibility Requirements"
							formRef={control}
							formName={"hasAccessibilityRequirements"}
							overrides={checkBoxStyle}
						/>
						{hasAccessibilityRequirements && (
							<ZenTextArea
								disabled={isReadOnly}
								label="Please list any relevant information"
								nameRef="accessibilityRequirements"
								formRef={control}
								inputError={errors.accessibilityRequirements}
								placeholder="Eg. Wheelchair access, mobility issues, hearing and/or visual aid"
								initialHeight={180}
								getValues={getValues}
							/>
						)}
	
						<ZenCheckbox label="Risk of Falls" disabled={isReadOnly} formRef={control} formName="hasRiskOfFalls" overrides={checkBoxStyle} />
						{hasRiskOfFalls && (
							<>
								<LabelSmall color={ZenTheme.colors.primaryGrey}>
									Information provided to client regarding falls prevention and supports available
								</LabelSmall>
								<ZenTextArea
									disabled={isReadOnly}
									label=""
									nameRef="riskOfFalls"
									formRef={control}
									inputError={errors.riskOfFalls}
									placeholder="Eg. 2 months"
									initialHeight={180}
									getValues={getValues}
								/>
							</>
						)}
	
						<ZenCheckbox
							label="Do you or someone you're caring for require palliative support care?"
							formRef={control}
							disabled={isReadOnly}
							formName="hasPalliativeSupportCare"
							overrides={checkBoxStyle}
						/>
						{hasPalliativeSupportCare && (
							<ZenTextArea
								disabled={isReadOnly}
								label=""
								nameRef="palliativeSupportCare"
								formRef={control}
								inputError={errors.palliativeSupportCare}
								placeholder="If yes, refer on to Palliative Care WA(1800 573 299) or NT(08 8951 6762)"
								initialHeight={180}
								getValues={getValues}
							/>
						)}
	
						<ZenCheckbox label="Do you have any allergies?" formRef={control} disabled={isReadOnly} formName="hasAllergies" overrides={checkBoxStyle} />
						{hasAllergies && (
							<>
								<ZenTextArea
									disabled={isReadOnly}
									label="Please provide allergen and reaction (incl. medications)"
									nameRef="allergies"
									formRef={control}
									inputError={errors.allergies}
									placeholder="Enter allergen, reaction, and medications"
									initialHeight={180}
									getValues={getValues}
								/>
								{/* Additional fields for safety plan and acknowledgment */}
								<ZenCheckbox
									label="Would you like to complete an HM safety plan?"
									formName="hasSafetyPlan"
									formRef={control}
									disabled={isReadOnly}
									overrides={checkBoxStyle}
								/>
								{watch("hasSafetyPlan") && (
									<ZenTextArea
										disabled={isReadOnly}
										label="Safety Plan Details"
										nameRef="safetyPlanDetails"
										formRef={control}
										inputError={errors.safetyPlanDetails}
										placeholder="Please include relevant safety plan details"
										initialHeight={180}
										getValues={getValues}
									/>
								)}
								<ZenCheckbox
									label="In the unlikely event of an emergency, HelpingMinds will not be responsible for any costs associated with accessing emergency services. These services include and are not limited to, police, fire, or ambulance support."
									formName="emergencyAcknowledgment"
									formRef={control}
									disabled={isReadOnly}
									overrides={checkBoxStyle}
								/>
								{errors.emergencyAcknowledgment && (
									<LabelSmall color="red">Acknowledgment is required</LabelSmall>
								)}
							</>
						)}
	
						<ZenCheckbox
							label="Do you have any other medical history we should be aware of?"
							formName="hasMedicalHistory"
							formRef={control}
							disabled={isReadOnly}
							overrides={checkBoxStyle}
						/>
						{hasMedicalHistory && (
							<>
								{/* Existing medical history textarea */}
								<ZenTextArea
									disabled={isReadOnly}
									label=""
									nameRef="medicalHistory"
									formRef={control}
									inputError={errors.medicalHistory}
									placeholder="E.g., other diagnosed medical conditions including transmittable and infectious conditions"
									initialHeight={180}
									getValues={getValues}
								/>
	
								{/* Substantive criminal history question */}
								<ZenCheckbox
									label="Do you have a substantive criminal history, pending charges or current proceedings through the court system?"
									formName="hasCriminalHistory"
									formRef={control}
									disabled={isReadOnly}
									overrides={checkBoxStyle}
								/>
								{watch("hasCriminalHistory") && (
									<>
										{/* Details for criminal history */}
										<ZenTextArea
											disabled={isReadOnly}
											label="Please provide further details"
											nameRef="criminalHistoryDetails"
											formRef={control}
											inputError={errors.criminalHistoryDetails}
											placeholder="Provide details of criminal history, pending charges, or court proceedings"
											initialHeight={180}
											getValues={getValues}
										/>
	
										{/* Referral to case review */}
										<ZenCheckbox
											label="Refer to case review and add case review date"
											formName="referToCaseReview"
											formRef={control}
											disabled={isReadOnly}
											overrides={checkBoxStyle}
										/>
										{watch("referToCaseReview") && (
											<>
												<ZenDatePicker
													inputError={errors.caseReviewDate}
													nullDefaultValue={true}
													formName="caseReviewDate"
													label="Case Review Date"
													formRef={control}
													disabled={isReadOnly}
													formRules={{
														required: "You must select a case review date.",
														validate: (value: string) => {
															if (!moment(value, "DD/MM/YYYY", true).isValid()) {
																return "Invalid date format. Please use DD/MM/YYYY.";
															}
															return null;
														},
													}}
												/>
												{errors.caseReviewDate && (
													<LabelSmall color="red">{errors.caseReviewDate.message}</LabelSmall>
												)}
											</>
										)}
									</>
								)}
							</>
						)}
					</IntakeScrollDiv>
				</div>
				<ErrorFieldTracker errorIDs={Object.keys(errors)} />
				{!isReadOnly && (
					<CancelAndSaveButtons
						cancelLabel="Back"
						cancelFn={() => history.goBack()}
						saveLabel="Next"
						saveFn={onSubmit}
					/>
				)}
			</form>
		</div>
	);
	
}
